var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('ABreadcrumb',{attrs:{"items":_vm.breadcrumb.items}}),_c('BreadHeader',{attrs:{"title":("Restore " + _vm.modelName)}}),_c('AError',{attrs:{"api":_vm.api}}),_c('v-card',[_c('v-card-text',[_c('span',[_vm._v("Are you sure you want to restore "),_c('b',[_vm._v(_vm._s(("" + _vm.modelName)))]),_vm._v("?")])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"isLoading":_vm.api.isLoading,"color":"primary"},on:{"click":function($event){return _vm.api.fetch()}}},[_vm._v(" Restore ")])],1)],1),_c('ADevNote',{attrs:{"text":[
        '✗ Validate authority',
        '✗ Validate ownership',
        '✓ Show breadcrumbs',
        '✓ Show title',
        '✗ Run Read API',
        '✗ Show Read API Loading',
        '✗ Show Read API Error',
        '✗ Replace model item name',
        '✓ Show warning',
        '✓ Show Form Button isLoading',
        '✓ Run Action API',
        '✓ Show Action API Loading',
        '✓ Show Action API Error',
        '✗ Show snackbar' ]}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }